import GatsbyImage, { FixedObject } from "gatsby-image"
import React, { FC, ReactNode } from "react"
import styled from "styled-components"

type Props = {
  children: ReactNode
  author: string
  photo: FixedObject
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: ${({ theme }) => theme.colors.blue[50]};
  color: ${({ theme }) => theme.colors.dark[0]};
  margin: 0 0 ${({ theme }) => theme.spacing[32]};

  @media (min-width: 1184px) {
    flex-direction: row;
  }
`

const Block = styled.div`
  max-width: 105rem;
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 1184px) {
    flex-direction: row;
  }
`

const BlockQuote = styled.blockquote`
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.dark[0]};
  display: flex;
  position: relative;
  line-height: 1.5;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};

  @media (min-width: 1184px) {
    padding: ${({ theme }) =>
      `${theme.spacing[10]} ${theme.spacing[32]} ${theme.spacing[10]} ${theme.spacing[20]}`};
  }
`

const Photo = styled.div`
  line-height: 0;
  text-align: center;
`

const Img = styled(GatsbyImage)`
  max-width: 100%;
`

const Footer = styled.footer`
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.dark[75]};
  margin-top: ${({ theme }) => theme.spacing[6]};
`

export const Quote: FC<Props> = ({ children, author, photo }) => (
  <Container>
    <Block>
      <Photo>
        <Img fixed={photo} />
      </Photo>

      <BlockQuote>
        {children}
        <Footer>{author}</Footer>
      </BlockQuote>
    </Block>
  </Container>
)
