import React, { FC, ReactNode } from "react"
import styled from "styled-components"

type BlockProps = { wide: boolean }

const Block = styled.div<BlockProps>`
  display: block;
  position: relative;
  max-width: ${({ wide }) => (wide ? "100%" : "48rem")};
  margin: 0 auto ${({ theme }) => theme.spacing[24]};
  padding: ${({ theme, wide }) => `0 ${wide ? "0" : theme.spacing[4]}`};

  @media (min-width: 768px) {
    padding: ${({ theme, wide }) => `0 ${wide ? "0" : theme.spacing[12]}`};
    margin: 0 auto ${({ theme }) => theme.spacing[32]};
  }
`

type Props = { children: ReactNode; wide?: boolean }

export const Container: FC<Props> = ({ children, wide = false }) => (
  <Block wide={wide}>{children}</Block>
)
