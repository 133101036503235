import React, { FC, ReactNode } from "react"
import styled from "styled-components"

const Container = styled.div<{ wide: boolean }>`
  display: block;
  position: relative;
  max-width: ${({ wide }) => (wide ? "100%" : "80rem")};
  margin: 4rem auto;

  & img {
    width: ${({ wide }) => (wide ? "100%" : "80rem")};
    height: auto;
    overflow: hidden;
  }
`

type Props = { children: ReactNode; wide?: boolean }

export const OverlappingImage: FC<Props> = ({ children, wide = false }) => (
  <Container wide={wide}>{children}</Container>
)
