import GatsbyImage, { FixedObject } from 'gatsby-image';
import React, { FC } from 'react';
import styled from 'styled-components';

type Props = {
  images: FixedObject[]
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10rem auto;
  max-width: 120rem;
`

const Row = styled.div<{ top?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 200px;
  justify-content: ${({ top }) => (top ? "flex-end" : "flex-start")};
  margin: 0.5rem 0;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: ${({ top }) => (top ? "baseline" : "start")};
  }
`

const Img = styled(GatsbyImage)`
  margin: 0.5rem 0;
  max-width: 100%;

  @media (min-width: 768px) {
    margin: 0 0.5rem;
  }
`

export const ImageCloud: FC<Props> = ({ images }) => {
  const [topLeft, topMiddle, topRight] = images
  const [, , , bottomLeft, bottomMiddle, bottomRight] = images

  return (
    <Container>
      <Row top>
        <Img fixed={topLeft} />
        <Img fixed={topMiddle} />
        <Img fixed={topRight} />
      </Row>
      {bottomLeft && bottomMiddle && bottomRight && (
        <Row>
          <Img fixed={bottomLeft} />
          <Img fixed={bottomMiddle} />
          <Img fixed={bottomRight} />
        </Row>
      )}
    </Container>
  )
}
