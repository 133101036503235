import React, { FC } from "react"
import styled from "styled-components"

const Container = styled.div`
  margin: 0 auto ${({ theme }) => theme.spacing[24]};

  @media (min-width: 768px) {
    margin: 0 auto ${({ theme }) => theme.spacing[32]};
    max-width: 48rem;
  }
`

const Block = styled.div`
  display: block;
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
`

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`

type Props = { id: string }

export const Video: FC<Props> = ({ id }) => (
  <Container>
    <Block>
      <Iframe src={`https://drive.google.com/file/d/${id}/preview`} />
    </Block>
  </Container>
)
