import React, { FC, ReactNode } from "react"
import styled, { css } from "styled-components"

type Props = {
  children: ReactNode[]
  listType: "ordered" | "unordered"
}

const BaseListStyle = css`
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.dark[125]};
  text-decoration: none;
  font-size: 1.1rem;
  margin: 0 0 1.25rem;
  line-height: 1.6rem;
  letter-spacing: 0.125px;
  padding: 0;
`

const UnorderedList = styled.ul`
  ${BaseListStyle}
  list-style-type: none;
`

const UnorderedListItem = styled.li`
  margin: 0 0 1rem 1.25rem;
  position: relative;

  &::before {
    content: "—";
    color: ${({ theme }) => theme.colors.blue[100]};
    margin-right: 2.5rem;

    position: absolute;
    left: -2rem;
    margin-right: 0;
  }

  &::selection {
    background: ${({ theme }) => theme.colors.blue[5]};
  }
`

const OrderedList = styled.ol`
  ${BaseListStyle}
  list-style-type: decimal-leading-zero;
`

const OrderedListItem = styled.li`
  margin: 0 0 0.5rem;

  &::selection {
    background: ${({ theme }) => theme.colors.blue[5]};
  }
`

export const List: FC<Props> = ({ children, listType }) =>
  listType === "unordered" ? (
    <UnorderedList>
      {children.map(el => (
        <UnorderedListItem>{el}</UnorderedListItem>
      ))}
    </UnorderedList>
  ) : (
    <OrderedList>
      {children.map(el => (
        <OrderedListItem>{el}</OrderedListItem>
      ))}
    </OrderedList>
  )
