import React, { FC, ReactNode } from "react"
import styled from "styled-components"

const Block = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  margin: 0 auto ${({ theme }) => theme.spacing[24]};

  @media (min-width: 768px) {
    flex-direction: row;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }

    max-width: 56rem;
    margin: 0 auto ${({ theme }) => theme.spacing[32]};
  }
`

const Image = styled.div`
  flex: 1;
  margin: ${({ theme }) => `0 0 ${theme.spacing[6]}}`};

  @media (min-width: 768px) {
    margin: ${({ theme }) => `0 ${theme.spacing[6]}} 0`};
  }
`

const Body = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: ${({ theme }) => `0 ${theme.spacing[8]}`};

  @media (min-width: 768px) {
    margin-left: ${({ theme }) => theme.spacing[6]};
  }
`

type Props = {
  children: ReactNode
  image: ReactNode
}

export const Figure: FC<Props> = ({ children, image }) => (
  <Block>
    <Image>{image}</Image>
    <Body>{children}</Body>
  </Block>
)
